// comingsoon.js

import React from "react";
import styled, { keyframes } from "styled-components";
import backgroundImage from "../assets/background/domain.png";

const ComingSoonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(${backgroundImage});
  background-size: cover;
  background-position: center;
  min-height: 80vh;
`;

const typingAnimation = keyframes`
  from { width: 0 }
  to { width: 100% }
`;

const ComingSoonText = styled.p`
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  overflow: hidden; /* Ensures text is cut off when typing animation runs */
  white-space: nowrap; /* Prevents text from wrapping */
  animation: ${typingAnimation} 4s steps(30, end) infinite; /* Typing animation */

  @media (max-width: 768px) {
    font-size: 1rem; /* Adjust font size for smaller screens */
  }
`;

const ComingSoon = () => {
  return (
    <ComingSoonContainer>
      <ComingSoonText>Stay tuned! More features to be added...</ComingSoonText>
    </ComingSoonContainer>
  );
};

export default ComingSoon;
