import React, { useEffect, useRef, useState } from "react";
import { Blurhash } from "react-blurhash";
import {
  FaDownload,
  FaGithub,
  FaInstagram,
  FaLinkedin,
  FaPlay,
  FaResearchgate,
  FaSearch,
  FaYoutube,
} from "react-icons/fa";
import profilePic from "../../assets/images/profile/profile2.jpg";
import Resume from "../../assets/resume.pdf";
import Video from "../../assets/videos/domain.mp4";
import { useIntersectionObserver } from "../../animation/intersectionObserver";

const IntroSection = () => {
  const [subheadingIndex, setSubheadingIndex] = useState(0);
  const [showVideo, setShowVideo] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);
  const elementsRef = useRef([]);
  const handleOverlayClick = () => {
    setShowVideo(false);
  };

  const handleVideoClick = (e) => {
    e.stopPropagation(); // Prevent the click from bubbling up to the overlay
  };

  const subheadings = [
    "Web Developer",
    "Data Engineer",
    "Mobile Developer",
    "UI/UX Designer",
    "Software Tester",
    "Content Creator",
  ];

  const scrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  useEffect(() => {
    const subheadingInterval = setInterval(() => {
      setSubheadingIndex((prevIndex) =>
        prevIndex === subheadings.length - 1 ? 0 : prevIndex + 1
      );
    }, 2000);

    return () => {
      clearInterval(subheadingInterval);
    };
  }, []);

  useEffect(() => {
    const img = document.createElement("img");
    img.onload = () => {
      setImageLoaded(true);
    };
    img.src = profilePic;
  }, [profilePic]);

  useIntersectionObserver(elementsRef);

  return (
    <div>
      <section
        id="home"
        className="flex flex-col justify-center items-center md:flex-row p-8 "
      >
        <div className="max-w-xl flex-1 mr-4 mb-4 text-center sm:!text-center md:!text-start font-suse ">
          <h1
            ref={(el) => (elementsRef.current[0] = el)}
            className="text-4xl sm:text-4xl md:text-5xl font-extrabold mb-4 zoom"
          >
            Adam Adha Bin Kamarul Zaman
          </h1>
          <h2
            ref={(el) => (elementsRef.current[1] = el)}
            className="text-2xl font-extrabold  text-purple-400 fade"
          >
            {subheadings[subheadingIndex]}
          </h2>
          <p
            ref={(el) => (elementsRef.current[2] = el)}
            className="my-4 text-lg font-normal font-bold fade"
          >
            Seeking a Full-Time Position in Web/Mobile Development, Data
            Engineering, UI/UX Designing or Software Testing.
          </p>
          <p
            ref={(el) => (elementsRef.current[3] = el)}
            className="my-4 text-lg font-normal fade"
          >
            Available from September 2024 onwards
          </p>
          <div className="flex flex-wrap justify-center bg-transparent">
            <a
              onClick={() => scrollToSection("skills")}
              ref={(el) => (elementsRef.current[4] = el)}
              className="text-sm bg-white text-black font-bold py-2 px-6 rounded mx-2 my-2 cursor-pointer w-40 text-center fade"
            >
              <div className="flex flex-row items-center justify-center">
                <div className="mr-2">
                  <FaSearch className="fa-beat" />
                </div>
                <div>My Skills</div>
              </div>
            </a>
            <a
              href={Resume}
              ref={(el) => (elementsRef.current[5] = el)}
              download="Resume Adam Adha Bin Kamarul Zaman (Fresh Graduate).pdf"
              className="text-sm bg-transparent text-white font-bold py-2 px-6 border border-white rounded mx-2 my-2 cursor-pointer w-40 text-center fade"
            >
              <div className="flex flex-row items-center justify-center">
                <div className="mr-2">
                  <FaDownload className="fa-beat" />
                </div>
                <div>Resume</div>
              </div>
            </a>
            <a
              onClick={() => setShowVideo(true)}
              ref={(el) => (elementsRef.current[6] = el)}
              className="text-sm text-black font-bold py-2 px-6 rounded mx-2 my-2 cursor-pointer bg-purple-400 w-40 text-center flex items-center justify-center fade"
            >
              <div className="flex flex-row items-center ">
                <div className="mr-2">
                  <FaPlay className="fa-beat" />
                </div>
                <div>Teaser Video</div>
              </div>
            </a>
          </div>

          <div
            ref={(el) => (elementsRef.current[7] = el)}
            className="flex flex-row items-center mt-8 fade"
          >
            <a
              href="https://www.linkedin.com/in/adamadha3/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-white text-xl mx-2 transition-colors duration-300"
            >
              <FaLinkedin className="text-2xl" />
            </a>
            <a
              href="https://www.researchgate.net/profile/Adam-Adha-2"
              target="_blank"
              rel="noopener noreferrer"
              className="text-white text-xl mx-2 transition-colors duration-300"
            >
              <FaResearchgate className="text-2xl" />
            </a>
            <a
              className="text-white text-xl mx-2 transition-colors duration-300"
              href="https://github.com/adamadha8"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaGithub className="text-2xl" />
            </a>
            <a className="text-gray-400 text-xl mx-2">
              <FaInstagram className="text-gray-400 text-2xl cursor-not-allowed" />
            </a>
            <a className="text-gray-400 text-xl mx-2">
              <FaYoutube className="text-gray-400 text-2xl cursor-not-allowed" />
            </a>
          </div>
        </div>
        <div
          ref={(el) => (elementsRef.current[8] = el)}
          className="flex-1 flex justify-center items-center relative mb-4 h-96 sm:h-[380px] max-w-[380px] zoom"
        >
          {!imageLoaded && (
            <Blurhash
              hash="LVEe*@~V-oslxcxuWAn#%2xFIUIU"
              width="100%"
              height="100%"
              resolutionX={32}
              resolutionY={32}
              punch={1}
            />
          )}

          <img
            onLoad={() => setImageLoaded(true)}
            loading="lazy"
            src={profilePic}
            alt="Adam Kz"
            className={`h-full w-full rounded-full object-cover ${
              imageLoaded ? "block" : "hidden"
            }`}
          />
        </div>
      </section>
      {showVideo && (
        <div
          className="fixed top-0 left-0 w-screen h-screen bg-black bg-opacity-80 flex justify-center items-center z-50"
          onClick={handleOverlayClick}
        >
          <div onClick={handleVideoClick}>
            <video controls className="max-h-full w-auto">
              <source src={Video} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
          <button
            onClick={() => setShowVideo(false)}
            className="absolute top-4 right-4 text-white text-3xl bg-transparent border-none cursor-pointer"
            style={{ zIndex: 51 }} // Ensure button is above other elements if needed
          >
            &times;
          </button>
        </div>
      )}
    </div>
  );
};

export default IntroSection;
