import React from "react";
import Modal from "react-modal";

const customModalStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.8)", // Darker overlay for emphasis
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 1000, // Ensuring the modal is on top
  },
  content: {
    backgroundColor: "#1b1e23", // Background color for content
    border: "none",
    borderRadius: "12px",
    padding: "20px",
    maxWidth: "600px",
    maxHeight: "80vh", // Limit the height to 80% of the viewport
    overflowY: "auto", // Enable scrolling for overflow content
    margin: "auto",
    boxShadow: "0 4px 20px rgba(0, 0, 0, 0.5)", // Subtle shadow for depth
    textAlign: "center",
    position: "relative", // To position the close button correctly
  },
};

export const ModalView = ({ isOpen, onClose, selectedItem }) => {
  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} style={customModalStyles}>
      <div className="bg-[#1b1e23] p-8 rounded-lg max-w-xl mx-auto text-center text-white shadow-lg relative font-suse">
        <button
          onClick={onClose}
          className="bg-red-600 text-white font-bold py-2 px-4 rounded-full cursor-pointer text-lg absolute top-4 right-4 transition-colors duration-300 hover:bg-red-500"
        >
          X
        </button>

        <img
          src={selectedItem.image}
          alt={selectedItem.title}
          className="w-full rounded-lg"
        />
        <h4 className="text-xl mt-4 mb-8 font-extrabold">
          {selectedItem.title}
        </h4>
        <h5 className="text-left text-sm font-semibold mb-2">Introduction</h5>
        <p className="text-justify text-sm">{selectedItem.description}</p>
        <h5 className="text-left text-sm font-semibold mt-4 mb-2">
          Technology Used
        </h5>
        <p className="text-justify text-sm">{selectedItem.technology}</p>
        <h5 className="text-left text-sm font-semibold mt-4 mb-2">
          GitHub/Figma
        </h5>
        <p className="text-justify text-sm">To be updated</p>
      </div>
    </Modal>
  );
};
