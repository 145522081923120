import React, { useEffect, useRef, useState } from "react";
import { Blurhash } from "react-blurhash";
import { activitiesData } from "../../constant/constant";
import { useIntersectionObserver } from "../../animation/intersectionObserver";

const Activities = () => {
  const [loadedImages, setLoadedImages] = useState({});
  const [showAll, setShowAll] = useState(false);
  const cardsRef = useRef([]);
  const activitySectionRef = useRef(null);

  const handleToggle = () => {
    setShowAll(!showAll);
    if (showAll) {
      // Use requestAnimationFrame to ensure smooth scrolling on iOS
      requestAnimationFrame(() => {
        // Force a reflow to update layout
        activitySectionRef.current.style.position = "relative";
        activitySectionRef.current.style.position = "";
        activitySectionRef.current.scrollIntoView({ behavior: "smooth" });
      });
    }
  };

  useIntersectionObserver(cardsRef, showAll);
  let itemIndex = 1;

  useEffect(() => {
    activitiesData.forEach(({ activities }) => {
      activities.forEach(({ image }) => {
        const img = new Image();
        img.src = image;
        img.onload = () => {
          setLoadedImages((prevState) => ({ ...prevState, [image]: true }));
        };
      });
    });
  }, []);

  const filteredActivitiesData = showAll
    ? activitiesData
    : activitiesData.filter(({ year }) => year === "2024");

  return (
    <section id="activity" ref={activitySectionRef} className="p-8 font-suse">
      <h2
        ref={(el) => (cardsRef.current[0] = el)}
        className="flex text-4xl font-extrabold text-white pb-2 mb-10 text-center border-b-2 border-purple-400 w-full max-w-[1200px] mx-auto"
      >
        Journey
      </h2>
      <div className="relative max-w-4xl mx-auto">
        {/* Timeline Line */}
        <div className="absolute left-1/2 transform -translate-x-1/2 h-full border-r-2 border-gray-600  lg:block"></div>

        {filteredActivitiesData.map(({ year, activities }, yearIndex) => (
          <div key={year} className="relative mb-16 pt-4 ">
            {/* Year Marker */}
            <div className="absolute left-1/2 transform -translate-x-1/2 -translate-y-8 bg-purple-400 text-black py-2 px-8 rounded-md shadow-lg font-bold">
              {year}
            </div>

            {activities.map(({ image, title, description }, activityIndex) => (
              <div
                ref={(el) => (cardsRef.current[itemIndex++] = el)}
                key={activityIndex}
                className={`flex items-center w-full ${
                  activityIndex % 2 === 0
                    ? "justify-start lg:pr-8"
                    : "justify-end lg:pl-8"
                } mt-8 flex-col sm:flex-row 
               ease-in-out  hover:scale-105 hover:shadow-lg group fade`} // Added hover effects
              >
                {/* Activity Content */}
                <div
                  className={`relative w-full max-w-xs p-6 bg-gray-800 text-white rounded-lg shadow-md transform transition-transform duration-500 ${
                    activityIndex % 2 === 0 ? "ml-0 lg:ml-8" : "mr-0 lg:mr-8"
                  }`}
                >
                  <div className="relative overflow-hidden w-full h-40 mb-3">
                    {!loadedImages[image] && (
                      <Blurhash
                        hash="LF9vC|qM#5NKlvIfVYTIzDg#v}X,"
                        width="100%"
                        height="100%"
                        resolutionX={32}
                        resolutionY={32}
                        punch={1}
                        className="w-full h-40"
                      />
                    )}
                    <img
                      loading="lazy"
                      src={image}
                      alt={title}
                      className={`w-full h-40 object-cover transition-opacity duration-500 ${
                        loadedImages[image] ? "opacity-100" : "opacity-0"
                      }`}
                    />
                  </div>
                  <h3 className="text-lg font-bold mb-2">{title}</h3>
                  <p className="leading-relaxed text-sm">{description}</p>
                </div>

                {/* Connector Line with Circle */}
                <div
                  className="absolute left-1/2 transform -translate-x-1/2 w-6 h-6 bg-purple-400 rounded-full border-4 border-gray-900 shadow-md hidden lg:block
                            transition-colors duration-300 ease-in-out group-hover:bg-purple-500 group-hover:border-white"
                ></div>
              </div>
            ))}
          </div>
        ))}
      </div>

      {/* Show All Button */}
      <div className="flex justify-center mt-8">
        <button
          onClick={handleToggle}
          className="bg-purple-400 text-black font-bold px-4 py-2 rounded-md hover:bg-purple-500"
        >
          {showAll ? "See Less" : "See More"}
        </button>
      </div>
    </section>
  );
};

export default Activities;
