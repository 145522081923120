import React, { useState } from "react";

const TempHeader = () => {
  const [isVisible, setIsVisible] = useState(true);

  const handleClose = () => {
    setIsVisible(false);
  };

  if (!isVisible) {
    return null; // Do not render the header if it is not visible
  }

  return (
    <header
      id="tempheader"
      className="relative bg-blue-800 text-white py-2 px- font-suse font-bold flex justify-between items-center"
    >
      <div className="container flex flex-row mx-auto px-4 max-w-[1200px] items-center">
        <div className="flex flex-1">
          <h1 className="text-center">
            Hey! I'm moving to a new domain! Visit me at{" "}
            <a
              href="https://adamadha.com"
              target="_blank"
              rel="noopener noreferrer"
              className="text-yellow-300 underline"
            >
              adamadha.com
            </a>
            .
          </h1>
        </div>
        <div className="flex flex-2">
          <button
            onClick={handleClose}
            className="text-white font-bold text-xl"
          >
            &times;
          </button>
        </div>
      </div>
    </header>
  );
};

export default TempHeader;
