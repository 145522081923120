import React, { useEffect, useState } from "react";
import {
  Route,
  BrowserRouter as Router,
  Routes,
  useLocation,
} from "react-router-dom";
import Company from "./components/AA/company";
import Footer from "./components/footer";
import Header from "./components/header";
import HomeSection from "./components/homeSection";
import LoadingSpinner from "./animation/loadingSpinner";
import TempHeader from "./components/tempHeader";

const AppContent = () => {
  const location = useLocation();
  const showHeaderFooter = location.pathname !== "/company";

  return (
    <>
      {showHeaderFooter && <TempHeader />}
      {showHeaderFooter && <Header />}
      <Routes>
        <Route path="/" element={<HomeSection />} />
        <Route path="/company" element={<Company />} />
      </Routes>
      {showHeaderFooter && <Footer />}
    </>
  );
};

const App = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate fetching data or resources
    setTimeout(() => {
      setLoading(false); // After fetching, set loading to false
    }, 500); // Adjust the timeout as per your actual loading needs
  }, []);

  return (
    <Router>
      {!loading && <AppContent />}
      {loading && <LoadingSpinner />} {/* Loading spinner component */}
    </Router>
  );
};

export default App;
