import { useEffect } from "react";

export const useIntersectionObserver = (elementsRef, activeFilter, showAll) => {
  useEffect(() => {
    const elements = elementsRef.current;

    if (!elements || !elements.length) return;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("show");
          }
          // else {
          //   if (
          //     (!activeFilter || entry.target.dataset.filter === activeFilter) &&
          //     entry.isIntersecting
          //   ) {
          //     entry.target.classList.add("show");
          //   } else {
          //     entry.target.classList.remove("show");
          //   }
          // }
          else {
            if (!showAll && entry.isIntersecting) {
              entry.target.classList.add("show");
            } else {
              entry.target.classList.remove("show");
            }
          }
        });
      },
      {
        threshold: 0.2, // Example threshold, adjust as needed
      }
    );

    elements.forEach((element) => {
      if (element) observer.observe(element);
    });

    return () => {
      elements.forEach((element) => {
        if (element) observer.unobserve(element);
      });
    };
  }, [elementsRef, activeFilter]);
};
